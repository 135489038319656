<template>
    <div class="container-fluid h-100">
        <div class="d-flex flex-column flex-lg-row h-100 w-100 justify-content-center align-items-center">
            <div class="col-md-5 col-xl-3 px-0 mb-5 mb-lg-0 mr-0 mr-lg-5">
                <div class="card-header">
                    <h4 class="card-title">Choose new password</h4>
                </div>
                <form @submit.prevent="reset" novalidate>
                    <div class="card-body">
                        <AppInput v-model="password"
                                  label="New password"
                                  placeholder="New password"
                                  field="password"
                                  :errors="errors"
                                  type="password"
                                  class="mb-3"/>
                    </div>
                    <div class="card-footer d-grid">
                        <button class="btn btn-lg btn-primary" type="submit"
                                :disabled="loading">Reset password</button>
                    </div>
                </form>
            </div>
            <div class="col-sm-3 col-lg-2 offset-lg-1">
                <h1 class="m-0"><strong>ProTrader</strong></h1>
            </div>
        </div>
    </div>
</template>

<script>
import PasswordService from '@/services/password.service';
import NotificationService from '@/services/notification.service';
import Errors from '@/utils/errors';
import AppInput from '@/components/form/AppInput';

export default {
  components: {
    AppInput,
  },
  data() {
    return {
      loading: false,
      password: null,
      errors: new Errors(),
    };
  },
  mounted() {
  },
  methods: {
    reset() {
      this.errors.clear();
      this.loading = true;

      PasswordService.reset(
        this.$route.params.email,
        this.$route.params.token,
        this.password,
      )
        .then((res) => {
          NotificationService.success(res.message);
          this.goToFrontPage();
        })
        .catch((err) => this.errors.set(err.response.data.errors))
        .finally(() => {
          this.loading = false;
        });
    },
    goToFrontPage() {
      this.$router.push('/');
    },
  },
};
</script>
